<template>
	<div class="applet">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div style="width: 55%;align-items: initial;flex-direction: column;" class="flex banner-body-title-span">
						<span style="font-size: 0.36rem; color: #FFFFFF;font-family: Source Han Sans CN;">力瀚电商小程序</span>
						<!-- <span
							style="font-size: 0.17rem;color: #fff;font-family: Source Han Sans CN;margin-top: 0.30rem;">全新升级直播+社区+视频模块，体验更佳</span> -->
						<span
							style="font-size: 0.12rem;color: #fff;font-family: Source Han Sans CN;margin-top: 0.10rem;">打造企业自己的多商户电商小程序，抢占微信千亿红利市场</span>
						<div class="sqsy" @click="customerService = true">咨询购买</div>
					</div>
				</div>
				<div class="banner-body-img flex">
					<img class="banner-body-imgL" src="../assets/img/applet-banner1.png">
					<img class="banner-body-imgR" src="../assets/img/applet-banner2.png">
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/Journalismbanner.png">
		</div>

		<div class="introduce">
			<div class="introduce-box">
				<div class="conversion-box flex">
					<div style="flex: 1;">
						<div
							style="width: 100%;text-align: left;color: #333333;font-size: 0.27rem;font-family: Source Han Sans CN;line-height: 0.40rem;">
							<span>力瀚商城小程序</span><br />
							<span>为商家抓住移动互联网新风口</span>
						</div>
						<div
							style="color: #666666;font-size: 0.12rem;margin-top: 0.40rem;text-align:left;padding-right: 1.00rem;">
							小程序的诞生已经成为移动互联网热门营销利器，力瀚为你
							借力微信11亿用户，让平台会员流量不断上涨</div>
					</div>
					<img style="width: 3.54rem;" src="../assets/img/applet-introduce.png">
				</div>
			</div>

			<div class="Cell flex">
				<div class="Cell-ce flex Cell-ce-bor">
					<div class="Cell-ce-tit">11<span style="font-size: 0.15rem;font-weight: 500;">亿</span></div>
					<div class="Cell-ce-lab">微信用户数</div>
				</div>
				<div class="Cell-ce flex Cell-ce-bor">
					<div class="Cell-ce-tit">60<span style="font-size: 0.15rem;font-weight: 500;">+</span></div>
					<div class="Cell-ce-lab">超级流量入口</div>
				</div>
				<div class="Cell-ce flex Cell-ce-bor">
					<div class="Cell-ce-tit">2.8<span style="font-size: 0.15rem;font-weight: 500;">亿</span></div>
					<div class="Cell-ce-lab">小程序日活跃量</div>
				</div>
				<div class="Cell-ce flex">
					<div class="Cell-ce-tit">5<span style="font-size: 0.15rem;font-weight: 500;">亿</span></div>
					<div class="Cell-ce-lab">小程序用户数</div>
				</div>
			</div>
		</div>

		<div class="Exhibition flex">
			<div class="Exhibition-box flex">
				<div class="Exhibition-box-L flex">
					<img src="../assets/img/microMall-l.png"
						style="margin-right: 0.40rem;position: relative;z-index: 103;width: 0.28rem;" @click="toleft">
					<img class="Exhibition-box-L-img"
						:src="require('../assets/img/microMall-microMall'+ (ExhibitionIndex) +'.png')">
					<img src="../assets/img/microMall-r.png"
						style="margin-left: 0.40rem;position: relative;z-index: 103;width: 0.28rem;" @click="toright">
				</div>
				<div class="Exhibition-box-L-L">
					<div class="Exhibition-box-r-tit">力瀚小程序商城界面展示</div>
					<div class="Exhibition-box-r-cell flex">
						<!-- <div class="Exhibition-box-r-cell-c flex" v-for="(item,index) in ExhibitionList" :key="index"
							:class="index == 0 ? 'iconcol' : ''">
							<img style="width: 27px;" :src="item.img">
							<div class="r-box-r-cell-span">{{item.title}}</div>
						</div> -->
						<div class="Exhibition-box-r-cell-c" v-for="(item,index) in ExhibitionList" :key="index">
							<img :style="{'width': ExhibitionIndex == item.LimgIndex ? item.withy : item.with}"
								:src="ExhibitionIndex == item.LimgIndex ? item.imgyl : item.img"
								@click="setIndex(index)">
						</div>

					</div>

					<div class="QRcode">
						<img style="width: 1.60rem;border-radius: 5px;" src="../assets/img/microMall-qrcode.png">
						<div class="QRcode-text">扫码查看演示</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sales">
			<div class="worry-tit">
				<span class="worry-tit-t">力瀚小程序为你聚集微信红利入口</span><br />
			</div>
			<div class="sales-box flex">
				<div style="height: 100%;align-items: initial;margin-right: 1.50rem;" class="flex">
					<div style="width: 3.20rem;border: 1px solid #ccc;">
						<div class="flex" @click="salesIndex = item.id"
							:class="salesIndex == item.id ? 'sales-box-cboxyl' : 'sales-box-cbox'"
							v-for="(item,index) in salesList" :key="item.id">
							<img style="margin-top: 0.02rem;" :src="salesIndex == item.id ? item.imgyl : item.img">
							<div style="flex: 1;text-align: left;margin-left: 0.20rem;">
								<div class="sales-box-cbox-tit">{{item.title}}</div>
								<div class="sales-box-cbox-lab">{{item.lable}}
								</div>
							</div>
						</div>

					</div>

				</div>
				<div style="width: 6.0rem;">
					<img class="salesimgbox" :src="require('../assets/img/applet-sales'+(salesIndex)+'.png')">
				</div>

			</div>
		</div>

		<div class="advertisement">
			<div class="worry-tit">
				<span class="worry-tit-t">蕴含丰富主页广告位展示</span><br />
				<span class="worry-tit-b">力瀚小程序的精美首页，拥有诸多精彩广告位，让小程序商城更有冲击力</span>
			</div>
			<div style="margin: 0 auto;margin-top: 0.60rem;max-width: 13.87rem;">
				<img style="width: 100%;" src="../assets/img/applet-advertisement.png">
			</div>
		</div>

		<!-- <div class="advantage">
			<div class="worry-tit">
				<span class="worry-tit-t">力瀚商城小程序的优势</span><br />
			</div>
			<div style="width: 50%; margin: 0 auto;margin-top: 60px;justify-content: space-between;" class="flex">
				<div class="advantage-cell">
					<img style="width: 40px;margin-top: 10px;" src="../assets/img/applet-advantage1.png" >
					<div style="font-size: 13px;font-weight: 500;color: #333333;margin-top: 20px;">支持定制开发</div>
					<div style="font-size: 12px;color: #8F8F8F;font-weight: 500;margin-top: 20px;">按照商家需求定制，功能灵活扩展，打造专属小程序</div>
				</div>
				<div class="advantage-cell">
					<img style="width: 40px;margin-top: 10px;" src="../assets/img/applet-advantage2.png" >
					<div style="font-size: 13px;font-weight: 500;color: #333333;margin-top: 20px;">提供系统源码</div>
					<div style="font-size: 12px;color: #8F8F8F;font-weight: 500;margin-top: 20px;">提供小程序源码版套餐选择，购买后即 可提供源码包</div>
				</div>
				<div class="advantage-cell">
					<img style="width: 40px;margin-top: 10px;" src="../assets/img/applet-advantage3.png" >
					<div style="font-size: 13px;font-weight: 500;color: #333333;margin-top: 20px;">完善开发文档</div>
					<div style="font-size: 12px;color: #8F8F8F;font-weight: 500;margin-top: 20px;">不仅提供源码，还提供完普的开发文 档，助力商家运营</div>
				</div>
				<div class="advantage-cell">
					<img style="width: 40px;margin-top: 10px;" src="../assets/img/applet-advantage4.png" >
					<div style="font-size: 13px;font-weight: 500;color: #333333;margin-top: 20px;">专业技术支持</div>
					<div style="font-size: 12px;color: #8F8F8F;font-weight: 500;margin-top: 20px;">N对1专业售后及技术开发支持随时提 供帮助支持服务</div>
				</div>
			</div>
			
			<div class="advantage-but">获取我的小程序</div>
		</div> -->

		<div class="applet-cel">
			<div class="worry-tit">
				<span class="worry-tit-t">为什么选择力瀚商城小程序</span>
			</div>

			<div class="applet-cel-box">
				<img style="width: 100%;" src="../assets/img/applet-cel-box.png">
			</div>
		</div>

		<div class="Marketing">
			<div class="Marketing-tit">
				<span class="Marketing-tit-tit">电商-移动营销全覆盖</span><br />
				<span class="Marketing-tit-lab">力瀚电商版是一款满足电商+移动电商的战略性产品，系统包含APP、微商城、小程序等多个移动终端，并实现数据同步，统一后台</span>
			</div>

			<div class="Marketing-imgbox flex">
				<div>
					<img class="Marketing-imgbox-img1" style="width: 2.96rem;" src="../assets/img/onlineRetailers-Marketing1.png">
					<div style="padding: 0.10rem 0;line-height: 0.20rem;">
						<div style="font-size: 0.13rem;color: #333;margin-bottom: 0.10rem;">小程序</div>
						<div style="font-size: 0.09rem;color: #999999;">搭建无需下载，用完即走的电商 小程序，抢占第一波红利</div>
					</div>
				</div>
				<div>
					<img class="Marketing-imgbox-img1" style="width: 2.96rem;" src="../assets/img/onlineRetailers-Marketing2.png">
					<div style="padding: 0.10rem 0;line-height: 0.20rem;">
						<div style="font-size: 0.13rem;color: #333;margin-bottom: 0.10rem;">APP</div>
						<div style="font-size: 0.09rem;color: #999999;">通过APP聚拢用户，随时推送 消息，提高用户的粘性</div>
					</div>
				</div>
				<div>
					<img class="Marketing-imgbox-img1" style="width: 2.96rem;" src="../assets/img/onlineRetailers-Marketing3.png">
					<div style="padding: 0.10rem 0;line-height: 0.20rem;">
						<div style="font-size: 0.13rem;color: #333;margin-bottom: 0.10rem;">微商城</div>
						<div style="font-size: 0.09rem;color: #999999;">玩转微信营销，可分享到外部平台推广，吸引流量</div>
					</div>
				</div>
			</div>
		</div>

		<div class="applet-cel">
			<div class="worry-tit">
				<span class="worry-tit-t">力瀚小程序精彩案例</span>
			</div>

			<div class="applet-cel-box">
				<img style="width: 100%;" src="../assets/img/applet-cel-box1.png">
				<!-- <img style="width: 100%;" src="../assets/img/applet-cel-box2.png"> -->
			</div>
		</div>

		<div class="programme">
			<div class="programme-tit">支持私有化部署，满足定制开发需求</div>
			<div class="programme-but flex">
				<div class="programme-but-input">打造专属移动商城，力瀚更懂你的需求</div>
				<div class="programme-but-but flex" @click="applyFromshow = true">点击定制</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	import customerService from '@/components/customerService.vue';
	export default {
		data() {
			return {
				applyFromshow: false,
				customerService: false,
				ExhibitionIndex: 1,
				LimgKey:0,
				salesIndex: 1,
				ExhibitionList: [{
						img: require('../assets/img/microMall-microMall-icon1.png'),
						imgyl: require('../assets/img/microMall-microMall-icon1yl.png'),
						title: '首页',
						LimgIndex: 1,
						withy: '1.26rem',
						with: '0.54rem'
					},
					{
						img: require('../assets/img/microMall-microMall-icon2.png'),
						imgyl: require('../assets/img/microMall-microMall-icon2yl.png'),
						title: '商品详情',
						LimgIndex: 2,
						withy: '1.28rem',
						with: '0.81rem'
					},
					// {
					// 	img: require('../assets/img/microMall-microMall-icon3.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon3yl.png'),
					// 	title: '直播',
					// 	withy: '1.28rem',
					// 	with: '0.62rem'
					// },
					// {
					// 	img: require('../assets/img/microMall-microMall-icon4.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon4yl.png'),
					// 	title: '短视频',
					// 	withy: '1.28rem',
					// 	with: '0.61rem'
					// },
					// {
					// 	img: require('../assets/img/microMall-microMall-icon5.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon5yl.png'),
					// 	title: '社区',
					// 	withy: '1.28rem',
					// 	with: '0.52rem'
					// },
					{
						img: require('../assets/img/microMall-microMall-icon6.png'),
						imgyl: require('../assets/img/microMall-microMall-icon6yl.png'),
						title: '店铺街',
						LimgIndex: 6,
						withy: '1.28rem',
						with: '0.62rem'
					},
					{
						img: require('../assets/img/microMall-microMall-icon7.png'),
						imgyl: require('../assets/img/microMall-microMall-icon7yl.png'),
						title: '会员中心',
						LimgIndex: 7,
						withy: '1.28rem',
						with: '0.81rem'
					},
					// {
					// 	img: require('../assets/img/microMall-microMall-icon8.png'),
					// 	imgyl: require('../assets/img/microMall-microMall-icon8yl.png'),
					// 	title: '资金管理',
					// 	withy: '1.28rem',
					// 	with: '0.81rem'
					// }
				],

				salesList: [{
						id: 1,
						img: require('../assets/img/applet-sales-icon1.png'),
						imgyl: require('../assets/img/applet-sales-icon1yl.png'),
						title: '丰富的引流入口',
						lable: '微信首页任务栏等60+小程序入口，让11亿 用户轻松发现你'
					},
					{
						id: 2,
						img: require('../assets/img/applet-sales-icon2.png'),
						imgyl: require('../assets/img/applet-sales-icon2yl.png'),
						title: '流量迅速变现入口',
						lable: '公众号、小程序、微信群互联互通，流量变现快速高效'
					},
					{
						id: 3,
						img: require('../assets/img/applet-sales-icon3.png'),
						imgyl: require('../assets/img/applet-sales-icon3yl.png'),
						title: '促销活动引爆销量',
						lable: '包含团购、分销、限时秒杀、拼团等热门电商促销活动玩法'
					}
				]
			}
		},
		components: {
			// Canvas，
			applyFrom,
			customerService
		},
		methods: {
			close() {
				this.applyFromshow = false;
			},

			closePopup() {
				this.customerService = false
			},
			setIndex(index){
				this.LimgKey = index;
				this.ExhibitionIndex = this.ExhibitionList[index].LimgIndex
			},
			
			toleft() {
				if (this.LimgKey > 0) {
					this.LimgKey--
					
				} else {
					this.LimgKey = 0
				}
				
				this.ExhibitionIndex = this.ExhibitionList[this.LimgKey].LimgIndex
			},
			toright() {
				if (this.LimgKey == 3) {
					console.log('++')
					this.LimgKey = 0
				} else {
					this.LimgKey++
				}
				this.ExhibitionIndex = this.ExhibitionList[this.LimgKey].LimgIndex
			}
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 750px) {
		.introduce-box {
			width: 80% !important;
		}

		.sales-box {
			width: 90% !important;
		}
		
		.Exhibition-box-r-cell-c{
			width: 40% !important;
		}
		
		.Exhibition-box{
			width: 95% !important;
		}
		
		.Exhibition-box-L-L{
			margin-left: 0 !important;
		}
		
		.Marketing-imgbox-img1{
			width: 2.5rem !important;
		}
		
		.Exhibition-box-L-img{
			width: 2.5rem !important;
		}
		
		.banner-body{
			width: 95% !important;
			margin: 0 auto;
		}
		.banner-body-title{
			width: 50% !important;
			.banner-body-title-span{
				width: 100% !important;
			}
		}
		
		.banner-body-img{
			width: 50% !important;
		}
		
		.Cell{
			width: 80% !important;
		}
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: 0.2s all linear;
		-webkit-transition: 0.2s all linear;
	}

	.banner {
		width: 100%;
		height: 3.60rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;

		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;

			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
				position: relative;

				.banner-body-imgL {
					position: absolute;
					left: 0;
					max-width: 2.00rem;
					z-index: 11;
					top: 0.40rem;
				}

				.banner-body-imgR {
					position: absolute;
					left: 1.80rem;
					max-width: 1.80rem;
					top: 0.76rem;
				}
			}

			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;

				// align-items: initial;
				.sqsy {
					width: 1.80rem;
					height: 0.50rem;
					border-radius: 6px;
					// margin: 0 auto;
					margin-top: 0.20rem;
					background-image: url(../assets/img/sqsy.png);
					font-size: 0.25rem;
					text-align: center;
					line-height: 0.50rem;
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.introduce {
		width: 100%;
		padding: 0.80rem 0;
		background-color: #f7f7f7;

		.introduce-box {
			width: 50%;
			margin: 0 auto;
			align-items: initial;
		}

		.Cell {
			width: 50%;
			height: 0.80rem;
			background-color: #fff;
			margin: 0 auto;
			// position: absolute;
			bottom: -0.50rem;
			border-radius: 0.10rem;
			margin-top: 0.50rem;
			// left: 0;
			// right: 0;
			// z-index: 11;

			.Cell-ce {
				flex: 1;
				height: 100%;
				flex-direction: column;

				.Cell-ce-tit {
					font-size: 0.35rem;
					font-weight: bold;
					color: #188CF2;
				}

				.Cell-ce-lab {
					color: #999999;
					font-size: 0.12rem;
				}
			}

			.Cell-ce-bor {
				position: relative;

				&:before {
					content: "";
					width: 0.01rem;
					height: 0.60rem;
					position: absolute;
					right: 0;
					margin-top: -0.30rem;
					top: 50%;
					background: #999999;
					opacity: .3;
				}
			}
		}
	}

	.iconcol {
		background-color: #fff;
		border-radius: 4px;

		.r-box-r-cell-span {
			color: #2E89F9 !important;
		}
	}

	.Exhibition {
		width: 100%;
		// height: 480px;
		padding: 0.60rem 0;
		background: linear-gradient(90deg, #2E89F9, #73B2FF);

		.Exhibition-box {
			// width: 50%;
			height: 100%;
			align-items: unset;
			
			.Exhibition-box-L-img{
				width: 3.11rem;
			}
			
			
			.Exhibition-box-L-L {
				flex: 1;
				height: 80%;
				width: 5.5rem;
				margin-left: 1.50rem;
				
			}

			.Exhibition-box-r-tit {
				text-align: left;
				font-size: 0.36rem;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #fff;
			}

			.QRcode {
				width: 100%;
				text-align: left;
				margin-top: 0.20rem;
				
				.QRcode-text {
					width: 1.60rem;
					padding: 0.10rem 0;
					font-size: 0.15rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
				}
			}

			.Exhibition-box-r-cell {
				// width: 5.00rem;
				flex-wrap: wrap;
				margin-top: 0.70rem;
				// justify-content: space-between;

				.r-box-r-cell-span {
					font-size: 0.12rem;
					color: #FFFFFF;
					margin-top: 0.10rem;
				}

				.Exhibition-box-r-cell-c {
					width: 24%;
					height: 2.00rem;
					margin-right: 1%;
					// margin-bottom: 70px;
					// flex-direction: column;
					position: relative;
					z-index: 103;
				}
			}
		}
	}

	@-webkit-keyframes salesani {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes salesani {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.sales {
		width: 100%;
		padding: 0.60rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.sales-box {
			// width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;

			.salesimgbox {
				width: 100%;
			}

			.sales-box-cbox {
				width: 3.00rem;
				border-bottom: 1px solid #cccccc2e;
				align-items: flex-start;
				padding: 0.20rem 0.10rem;
				position: relative;
				z-index: 103;

				.sales-box-cbox-tit {
					font-size: 0.16rem;
					color: #333;
				}

				.sales-box-cbox-lab {
					font-size: 0.14rem;
					color: #888;
					margin-top: 0.10rem;
				}

			}

			.sales-box-cboxyl {
				width: 3.00rem;
				align-items: flex-start;
				padding: 0.20rem 0.10rem;
				border-bottom: 1px solid #cccccc2e;
				background: linear-gradient(90deg, #2E89F9, #73B2FF);
				position: relative;
				z-index: 103;

				.sales-box-cbox-tit {
					font-size: 0.16rem;
					color: #fff;
				}

				.sales-box-cbox-lab {
					font-size: 0.14rem;
					color: #fff;
					margin-top: 0.10rem;
				}
			}

			.sales-box-cell {
				flex: 1;
				text-align: left;
				padding-right: 0.20rem;
			}
		}
	}

	.advertisement {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666;
			}
		}
	}

	.advantage {
		width: 100%;
		width: 100%;
		padding: 0.60rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.advantage-but {
			width: 1.30rem;
			height: 0.40rem;
			line-height: 0.40rem;
			text-align: center;
			font-size: 0.12rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			margin: 0 auto;
			margin-top: 0.60rem;
			background: linear-gradient(90deg, #2E89F9, #73B2FF);
		}

		.advantage-cell {
			width: 1.60rem;
			height: 1.50rem;
			padding: 0.10rem;
			box-shadow: 0px 3px 7px 0px rgba(205, 205, 205, 0.21);
			border-radius: 3px;
			text-align: left;
		}
	}

	.applet-cel {
		width: 100%;
		padding: 0.60rem 0;

		// background-color: #f7f7f7;
		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666;
			}
		}

		.applet-cel-box {
			max-width: 13.40rem;
			margin: 0 auto;
			margin-top: 0.60rem;
		}
	}

	.Marketing {
		width: 100%;
		background-color: #f7f7f7;
		padding: 0.60rem 0;

		.Marketing-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.Marketing-tit-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.Marketing-tit-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.Marketing-imgbox {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;
			// justify-content: space-between;
		}
	}

	.programme {
		width: 100%;
		height: 2.00rem;
		background-image: url(../assets/img/dg-bg.png);
		background-repeat: no-repeat;

		.programme-tit {
			width: 100%;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			padding: 0.40rem 0;
		}

		.programme-but {
			// width: 40%;
			margin: 0 auto;

			.programme-but-input {
				// width: 320px;
				padding: 0 0.30rem;
				height: 0.45rem;
				border: 1px solid #ccc;
				font-size: 0.15rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.45rem;
			}

			.programme-but-but {
				width: 1.50rem;
				height: 0.45rem;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 0.60rem;
				border-radius: 5px;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
			}
		}
	}
</style>
